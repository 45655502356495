@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#alert-message {
  position: fixed;
  z-index: 100;
  left: 2rem;
  top: 2rem;
  display: flex;
  flex-direction: column;
}

.alert-message-box {
  border-radius: 8px;
  background-color: rgb(9, 40, 63);
  padding: 1rem;
  box-sizing: border-box;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert-message-box.success {
  background-color: #2e8540; /* Green for success */
}

.alert-message-box.error {
  background-color: #b52e31; /* Red for error */
}

.alert-icon {
  margin-right: 10px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}
