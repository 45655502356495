


.search-image-insight {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.adjust-footer-div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}


.align-text-left {
    text-align: left;
}



.afifi-mobile-logo {
    width: 7.813rem;
}

.mobile-headers-box {
    display: none;
}

.display-grid {
    display: none;
}

.apear-under-1024{
    display: none !important;
}


.apear-under-1024-grid {
    display: none !important;
}


.unallow-overflow {
    position: relative;
    overflow: hidden;
}
.loading-screen {
    position: relative;
    box-sizing: inherit;
    width: 100vw;
    height: 100vh;
    background: #09283F;
}


.loading-screen__content{
    color: white;
    position: absolute;
    top: 43%;
    left: 41%;
}


@keyframes heartbeat {
    0%, 100% {
        transform: scale(1);
        filter: drop-shadow(0 0 10px white);
    }
    25% {
        transform: scale(1.1);
        filter: drop-shadow(0 0 15px white);
    }
    50% {
        transform: scale(1);
        filter: drop-shadow(0 0 10px white);
    }
    75% {
        transform: scale(1.05);
        filter: drop-shadow(0 0 12px white);
    }
}

.adjust-cookies-mobile > div:nth-child(1) {
    flex: 1 0 !important;
}

.practice-areas-organizer{
    display: flex;
    width: 100%;
}
.search-practice-areas-a-tag {
    height: 250px;
}


.scroll-to-top-button {
    position: fixed;
    bottom: 1.25rem;
    right: 1.25rem; /* Move to the right side */
    width: 3.125rem;
    height: 3.125rem;
    background-color: #09283F; /* Navy blue color */
    color: white;
    border: none;
    border-radius: 50%; /* Make it round */
    font-size: 1.25rem;
    text-align: center;
    line-height: 3.125rem; /* Vertically center the text */
    cursor: pointer;
    z-index: 1000; /* Ensure it's above other elements */
  }

.scroll-to-top-button:hover {
background-color: #09283F; /* Darker navy blue on hover */
}

.adjust-cookies-mobile {
    display:flex;
    flex-direction: column !important;
}

@media (max-width: 1024px) {
    body {
        display: block;
        width: max-content;
        height: max-content;
    }

    body, html {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }


    #root {
        width: 100vw;
        overflow-x: hidden;
    }

    .search-practice-areas-a-tag{
        height: 200px;
    }
    .loading-screen__content{
        color: white;
        position: absolute;
        top: 45%;
        left: 22.5%;
    }

    .loading-logo {
        width: 200px;
        height: 54px;
    }

    .fix-screen-overflow {
        overflow: hidden;
    }

    .main-headers {
        font-size: 1.875rem !important;
        max-width: 350px;
    }

    .header-sub-text {
        font-size: 0.75rem !important;
    }

    .seven-percent-left {
        left: 7% !important;
    }

    .mobile-headers-box {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1.5rem;
        align-items: baseline;
        top: 48%;
        max-width: 80%;
    }

    .mobile-headers-box-flex-end {
        left: auto !important;
        right: 7% !important;
    }

    .top-important-30 {
        top: 30% !important;
    }

    .aling-left {
        text-align: left !important;
    }

    .max-width-80 {
        max-width: 80%;
    }

    .under-1024-disapear{
        display: none !important;
    }

    .font-26 {
        font-size: 1.4rem !important;
    }

    .font-17 {
        font-size: 0.9rem !important;
    }

    .display-grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    }

    .display-grid-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
    }

    .responsive-image-box {
        width: 97.22vw; /* Set width to approximately 720px on a 1440px wide screen */
        padding-top: calc(97.22vw / 1.4); /* Calculate height based on the aspect ratio */
        position: relative;
        overflow: hidden;
    }

    .semi-responsive-image-box {
        width: 80vw; /* Set width to approximately 720px on a 1440px wide screen */
        padding-top: calc(80vw / 1.4); /* Calculate height based on the aspect ratio */
        position: relative;
        overflow: hidden;
    }

    .semi-responsive-image-box img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }

    .responsive-image-box img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }

    .no-absolute {
        position: relative !important;
    }

    .font-40 {
        font-size: 2.5rem !important;
    }

    .font-32 {
        font-size: 2rem !important;
    }

    .font-24 {
        font-size: 1.5rem !important;
    }
    
    .margin-left{
        margin-left: 0 !important;
    }

    .margin-left-16{
        margin-left: 1rem !important;
    }
    .page-annoying-section {
        display: flex !important;
        flex-direction: column !important;
        padding: 1rem;
        box-sizing: border-box;
    }

    .mobile-align-center{
        align-items: center !important;
    }

    .margin-right-zero {
        margin-right: 0rem !important;
      }
      
    .page-gap {
        gap: 1rem;
    }

    .fix-alignment {
        width: 80vw !important;
    }

    .padding-top-0 {
        padding-top: 0 !important;
    }
    .apear-under-1024 {
        display: flex !important;
    }

    .page-annoying-header {
        font-size: 2.5rem !important;
        height: auto !important;
        line-height: 4rem !important;
        padding: 1.25rem 0rem 1.2rem 0rem !important;
    }

    .top-20{
        top: 20% !important;
    }
    .mobile-80vw {
        width: 80vw !important;
    }

    .fix-our-professionals-big-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 0.5rem;
    }

    .mobile-30vh {
        height: 30vh !important;
    }
    .our-professional-column-fix {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    .padding-bottom-50{
        padding-top: 40px !important;
    }

    .apear-under-1024-grid{
        display: grid !important;
    }

    .adjust-insight{
        top: 60% !important;
    }

    .adjust-practices {
        top: 40% !important;
    }

    .image-gray-scale {
        filter: grayscale(30%);
    }

    .fix-dat-drawer{
        background: #09283F;
    }

    .mobile-screen-max-width {
        max-width: 80vw !important;
    }

    .zero-margins {
        margin: 0 !important;
    }

    .zero-margins > p {
        font-size: 1rem !important;
        margin: 0 !important;
    }

    .zero-margins-two {
        margin: 0 !important;
    }
    .margin-top-60 {
        margin-top: 3.7rem !important;
    }
    .search-page-intro {
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 1.25rem;
        flex-wrap: wrap;
    }

    .search-page-font-size {
        font-size: 1.5rem !important;
        font-weight: 600 !important;
    }
    .profile-card-mobile {
        width: 90vw !important;
        padding: 0 !important;
    }
    .card-mobile-paragraph{
        font-size: 0.75rem !important;
    }
    .card-content-max-height{
        max-height: 70% !important;
    }
    .image-wrap {
        flex: 1;
    }

    .search-insight-mobile-box {
        width: 80vw !important;
    }

    .adjust-cookies-mobile{
        transform: translate(-50%, -50%) !important;
        font-size: 1rem;
    }
    .adjust-cookies-mobile > div:nth-child(2) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 90%;
    }

    .adjust-cookies-mobile > div:nth-child(1) {
        flex: 1 0 !important;
    }

    #rcc-decline-button {
        width: 100%;
    }
    #rcc-confirm-button {
        width: 100%;
    }
    .remove-all-margins {
        margin: 0 !important;
    }
}
